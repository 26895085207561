/* eslint-disable func-names */
/* eslint-disable no-undef */
/* eslint-disable react/no-this-in-sfc */
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import correctIcon from 'src/assets/images/_shared/correct_icon.svg';
import wrongSvg from 'src/assets/images/_shared/wrong_icon.svg';
import {
  MapContainerPL,
  MapContainerDE,
  MapContainerEN,
  MapContainerRU,
  MapContainerUA,
  MapContainerFR,
  MapContainerHU,
  Results,
  ResultsHeader,
  ResultsList,
  ResultElement,
  ResultsContainer,
  ResultLegend,
  LegendElement,
  LegendIndicator,
  LegendText,
  ResultElementIcon,
} from './styles';

const isWinner = (results) => {
  let winnerCounter = 0;

  results.forEach(({ type }) => {
    // eslint-disable-next-line no-plusplus
    if (type === 'winner') winnerCounter++;
  });
  return winnerCounter === 3;
};

const MapGame = ({ data }) => {
  const { exports, frontmatter } = data;
  const {
    countries, winners, losers, draw,
  } = exports;
  const {
    resultsHeader,
    legendFirstLabel,
    legendSecondLabel,
    language,
    winnerMessage,
  } = frontmatter;

  const [results, setResults] = useState([]);
  const dispatch = useDispatch();

  const onListElementClick = (element) => {
    if (element) {
      const filterArr = results.filter((el) => element !== el);
      setResults(filterArr);
      document.querySelector(`.${element.country}`).style.fill = '#4E4D4B';
      dispatch({ type: 'SET_AVATAR_MESSAGE', payload: '' });
    }
  };

  const MapSelector = () => {
    switch (language) {
      case 'PL':
        return <MapContainerPL />;
      case 'EN':
        return <MapContainerEN />;
      case 'FR':
        return <MapContainerFR />;
      case 'RU':
        return <MapContainerRU />;
      case 'DE':
        return <MapContainerDE />;
      case 'UA':
        return <MapContainerUA />;
      case 'HU':
        return <MapContainerHU />;
      default:
        return <MapContainerPL />;
    }
  };

  useEffect(() => {
    if (isWinner(results)) dispatch({ type: 'SET_AVATAR_MESSAGE', payload: winnerMessage });

    const listner = (e) => {
      const el = e.target.className.baseVal.split(' ')[1];
      const isExist = results.filter((result) => result.country === el).length;

      if (isExist) {
        e.target.style.fill = '#4E4D4B';
        dispatch({ type: 'SET_AVATAR_MESSAGE', payload: '' });
        return setResults(results.filter((result) => result.country !== el));
      }

      if (winners.includes(el)) {
        if (results.length <= 2) {
          e.target.style.fill = '#6a8e85';
          dispatch({ type: 'SET_AVATAR_MESSAGE', payload: e.target.message });
          return setResults([...results, { country: el, type: 'winner' }]);
        }
      } else if (draw.includes(el)) {
        if (results.length <= 2) {
          e.target.style.fill = '#b28a4a';
          dispatch({ type: 'SET_AVATAR_MESSAGE', payload: e.target.message });
          return setResults([...results, { country: el, type: 'draw' }]);
        }
      } else if (losers.includes(el)) {
        if (results.length <= 2) {
          e.target.style.fill = '#a45f4a';
          dispatch({ type: 'SET_AVATAR_MESSAGE', payload: e.target.message });
          return setResults([...results, { country: el, type: 'loser' }]);
        }
      }
      return null;
    };

    countries.forEach(({ country, message }) => {
      const element = document.querySelector(`.${country}`) || document.querySelector(`${country}`);
      element.addEventListener('click', listner);
      element.message = message;
    });
    return () => {
      countries.forEach(({ country }) => {
        document
          .querySelector(`.${country}`)
          .removeEventListener('click', listner);
      });
    };
  }, [results]);

  return (
    <>
      {MapSelector()}
      <ResultsContainer>
        <Results>
          <ResultsHeader>
            {resultsHeader}
            :
          </ResultsHeader>
          <ResultsList>
            <ResultElement
              type={results[0] && results[0].type}
              onClick={() => onListElementClick(results[0])}
            >
              <span>{results[0] && results[0].country}</span>
              {results[0] && (
                <ResultElementIcon type={results[0] && results[0].type}>
                  {results[0].type === 'winner' ? (
                    <img src={correctIcon} alt="correct_icon" />
                  ) : (
                    <img src={wrongSvg} alt="wrong_icon" />
                  )}
                </ResultElementIcon>
              )}
            </ResultElement>
            <ResultElement
              type={results[1] && results[1].type}
              onClick={() => onListElementClick(results[1])}
            >
              <span>{results[1] && results[1].country}</span>
              {results[1] && (
                <ResultElementIcon type={results[1] && results[1].type}>
                  {results[1].type === 'winner' ? (
                    <img src={correctIcon} alt="correct_icon" />
                  ) : (
                    <img src={wrongSvg} alt="wrong_icon" />
                  )}
                </ResultElementIcon>
              )}
            </ResultElement>
            <ResultElement
              type={results[2] && results[2].type}
              onClick={() => onListElementClick(results[2])}
            >
              <span>{results[2] && results[2].country}</span>
              {results[2] && (
                <ResultElementIcon type={results[2] && results[2].type}>
                  {results[2].type === 'winner' ? (
                    <img src={correctIcon} alt="correct_icon" />
                  ) : (
                    <img src={wrongSvg} alt="wrong_icon" />
                  )}
                </ResultElementIcon>
              )}
            </ResultElement>
          </ResultsList>
        </Results>
        <Results style={{ marginTop: '1rem' }}>
          <ResultLegend>
            <LegendElement>
              <LegendIndicator>- -</LegendIndicator>
              <LegendText>{legendFirstLabel}</LegendText>
            </LegendElement>
            <LegendElement>
              <LegendIndicator style={{ color: '#abff97' }}>
                - -
              </LegendIndicator>
              <LegendText>{legendSecondLabel}</LegendText>
            </LegendElement>
          </ResultLegend>
        </Results>
      </ResultsContainer>
    </>
  );
};

export default MapGame;
