import React from 'react';
import TextAnimation from 'src/components/mobile/textAnimation/textAnimation';
import BackgroundPhoto from 'src/components/mobile/backgroundPhoto/backgroundPhoto';
import { BlockContainer, ChpaterTitle } from '../../_styles';


const Slide = ({ query, title }) => (
  <BlockContainer height="700px">
    <BackgroundPhoto fixed={query.file.childImageSharp.fixed} scale alt="slide-photo">
      <ChpaterTitle marginTop="14rem">{title}</ChpaterTitle>
      <TextAnimation body={query.mdx.body} titleStyle={{ margin: '' }} containerStyle={{ height: '50%' }} />
    </BackgroundPhoto>
  </BlockContainer>
);


export default Slide;
