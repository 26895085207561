import React from 'react';
import MainSlider from 'src/components/desktop/mainSlider/mainSlider.jsx';

// Dodane testowo slajdy z rozdziału pierwszego!!!
// TODO: zmienić na właściwe slajdy
import Slide1 from 'src/slides/desktop/ru/chapter_3/sub_4/slide1';
import WrapperMobile from 'src/slides/mobile/ru/chapter_3/sub_4/wrapperMobile';

import { isMobile } from 'react-device-detect';

// import Slide
import SEO from 'src/components/_shared/seo';


const allSlides = [<Slide1 />];

const exceptions = [];

const Desktop = () => (
  <>
    <SEO title="Концепция федерации Юзефа Пилсудского I Варшавская битва" description="Как Пилсудский пытался создать «Междуморие» против России." lang="ru" />
    <MainSlider slides={allSlides} exceptions={exceptions} />
  </>
);

const Mobile = () => (
  <>
    <SEO title="Концепция федерации Юзефа Пилсудского I Варшавская битва" description="Как Пилсудский пытался создать «Междуморие» против России." lang="ru" />
    <WrapperMobile />
  </>
);

const Page = () => {
  if (isMobile) return <Mobile />;
  return <Desktop />;
};

export default Page;
