import styled from 'styled-components';
import MapPL from '../../../../assets/images/chapter_3/sub_4/Baza2.inline.svg';
import MapEN from '../../../../assets/images/chapter_3/sub_4/map_EN.inline.svg';
import MapFR from '../../../../assets/images/chapter_3/sub_4/map_FR.inline.svg';
import MapRU from '../../../../assets/images/chapter_3/sub_4/map_RU.inline.svg';
import MapDE from '../../../../assets/images/chapter_3/sub_4/map_DE.inline.svg';
import MapUA from '../../../../assets/images/chapter_3/sub_4/map_UA.inline.svg';
import MapHU from '../../../../assets/images/chapter_3/sub_4/map_HU.inline.svg';

const ResultElementBackground = (type) => {
  switch (type) {
    case 'winner':
      return 'rgba(121, 175, 162,0.7)';
    case 'draw':
      return 'rgba(224, 169, 79,0.7)';
    case 'loser':
      return 'rgba(204, 108, 78,0.7)';
    default:
      return '';
  }
};
const ResultElementIconBackground = (type) => {
  switch (type) {
    case 'winner':
      return 'rgba(121, 175, 162,1)';
    case 'draw':
      return 'rgba(224, 169, 79,1)';
    case 'loser':
      return 'rgba(204, 108, 78,1)';
    default:
      return '';
  }
};

const mapStyle = `
width: 100%;
height: 100%;
transform: translateX(20%);
z-index: 0;
position: absolute;
top: 0;
right: 0;
pointer-events: none;

@media (max-width: 1600px){
  transform: translateX(25%);
}
`

export const MapContainerPL = styled(MapPL)`
  ${mapStyle}
`;
export const MapContainerDE = styled(MapDE)`
  ${mapStyle}
`;
export const MapContainerEN = styled(MapEN)`
  ${mapStyle}
`;
export const MapContainerRU = styled(MapRU)`
  ${mapStyle}
`;

export const MapContainerUA = styled(MapUA)`
  ${mapStyle}
`;
export const MapContainerHU = styled(MapHU)`
  ${mapStyle}
`;
export const MapContainerFR = styled(MapFR)`
  ${mapStyle}
`;

export const ResultsContainer = styled.div`
  position: absolute;
  right: 2%;
  top: 40%;
  transform: translateY(-50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 1600px){
    max-width: 16rem
  }
`;

export const Results = styled.div`
  padding: 0.5rem;
  background: rgba(72, 66, 66, 0.7);
  border-radius: 5px;
  width: 100%;
`;

export const ResultsHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(64, 58, 57, 0.8);
  color: #f8f7f2;
  border: 1px solid rgba(168, 169, 168, 0.6);
  padding: 1rem 3rem;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  font-family: "Red Hat Display";
  font-size: 0.875rem;
  font-weight: 700;
  letter-spacing: 0.28px;
  user-select: none;
`;

export const ResultsList = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  background: rgba(72, 66, 66, 0.8);
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  border-bottom: 1px solid rgba(168, 169, 168, 0.6);
  border-left: 1px solid rgba(168, 169, 168, 0.6);
  border-right: 1px solid rgba(168, 169, 168, 0.6);
`;

export const ResultElement = styled.div`
  border: 1px solid rgba(255, 255, 255, 0.6);
  border-radius: 21px 22px 22px 5px;
  width: 100%;
  height: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #f4f9f9;
  font-family: "Abril Fatface";
  font-size: 1rem;
  margin: 0.5rem 0;
  background: ${({ type }) => ResultElementBackground(type)};
  position: relative;
  padding-right: 2rem;
  user-select: none;
  cursor: pointer;
  

  span::first-letter{
      text-transform: uppercase;
  }
`;

export const ResultElementIcon = styled.div`
  width: 2rem;
  height: 2rem;
  position: absolute;
  right: 2%;
  top: 50%;
  transform: translateY(-50%);
  border-radius: 20px;
  border: 1px solid #fffffb;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ type }) => ResultElementIconBackground(type)};

  img{
    width: 50%;
  }
`;

export const ResultLegend = styled.div`
  padding: 1rem;
  border-radius: 5px;
  border: 1px solid rgba(168, 169, 168, 0.6);
  user-select: none;
`;

export const LegendElement = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 0;
`;

export const LegendIndicator = styled.p`
  font-weight: 700;
  color: #fffffb;
  line-height: 0;
  font-size: 2rem;
  font-family: "Red Hat Display";
`;

export const LegendText = styled.p`
  color: #f5f4ee;
  font-family: "Red Hat Display";
  font-size: 0.625rem;
  font-weight: 400;
  margin-left: 0.5rem;
  @media (max-width: 1600px){
    text-align: right;
    ont-size: 0.55rem
  }
`;
