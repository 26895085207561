import React from 'react';
import {
  ParallaxMordo,
  Mordo,
} from 'src/components/desktop/parallax/ParallaxMordo.jsx';
import LeftColContent from 'src/components/desktop/leftColContent/leftColContent';
import { useStaticQuery, graphql } from 'gatsby';
import { SlideLarge, FlexWrapper } from '../../_styles';
import MapGame from '../../../../../components/desktop/mapGame/mapGame';

const Slide = ({ query }) => {
  const assetQuery = useStaticQuery(graphql`
    query {
      avatar: file(relativePath: { eq: "chapter_3/sub_4/avatar.png" }) {
        childImageSharp {
          fixed(width: 1920) {
            ...GatsbyImageSharpFixed_withWebp_noBase64
          }
        }
      }
    }
  `);
  return (
    <SlideLarge>
      <ParallaxMordo>
        <Mordo
          mainscene
          move={{ x: -20, y: -10 }}
          background={{ color: 'transparent' }}
        >
          <FlexWrapper style={{ background: '#3C3735' }} />
        </Mordo>
        <Mordo flexi scene={2} move={{ x: -20, y: -10 }} customStyle={{ pointerEvents: 'none' }}>
          <div style={{ width: '60%' }}>
            <LeftColContent
              body={query.leftColumn.body}
              avatarImage={assetQuery.avatar.childImageSharp.fixed}
              style={{ position: 'relative', zIndex: 1 }}
            />
          </div>
        </Mordo>
        <Mordo
          // flexi
          scene={1}
          move={{ x: -10, y: -5 }}
        >
          <div style={{ height: '100%', width: '100%' }}>
            <MapGame data={query.data} />
          </div>
        </Mordo>
      </ParallaxMordo>
    </SlideLarge>
  );
};

export default Slide;
